


















import { Component, Vue, Watch, Ref } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import TimelineSearch from '@/components/organisms/TimelineSearch.vue'
import Conditions from '@/components/organisms/Conditions.vue'
import StudentInformationTimeline from '@/components/organisms/StudentInformationTimeline.vue'

@Component({
  components: {
    TimelineSearch,
    TitleBase,
    TitleTextBase,
    Conditions,
    StudentInformationTimeline,
  },
})
export default class Timeline extends Vue {
  private conditionsDatas = []

  private timelineDatas = []

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private lastData: any = {}

  private async loadDatas() {
    const params = this.conditionsDatas.reduce((accumlator: any, condition: any): any => {
      accumlator[condition.key] = condition.value
      return accumlator
    }, {})

    // 期間開始に最終レコードの学習開始日時を設定
    if (this.lastData.startedAt) params.startedAtLteq = this.lastData.startedAt

    // TODO: 生徒アイコンは未定
    await Vue.prototype.$http.httpWithToken
      .post('/timelines', { branchId: this.branchId, q: params, lastData: this.lastData })
      .then((res: any) => {
        const add_timelines: [] = res.data.timelines.map((timeline: any) => {
          return {
            id: timeline.studentCode,
            name: timeline.nickname,
            icon: '',
            school: timeline.schoolName,
            grade: timeline.gradeName,
            date: timeline.studyDate,
            time: timeline.studyTime,
            type: timeline.studyType,
            item_text: timeline.lessonName,
            material: timeline.lessonType,
            study_time: timeline.duration,
            study_count: timeline.completeCount,
            result: { all: timeline.questionCount, correct: timeline.correctCount },
            learningFlag: timeline.learningFlag,
            resultDrillId: timeline.resultDrillId,
            resultDrillVersion: timeline.resultDrillVersion,
          }
        })
        this.timelineDatas.push(...add_timelines)
        this.lastData = res.data.meta.lastData
        // 最終行の場合、監視終了
        if (!add_timelines.length) this.observer.disconnect()
      })
  }

  private async setConditionsDatas(datas: any) {
    this.conditionsDatas = datas
    this.setTimelineCookie()
  }

  @Ref() timelineSearch!: TimelineSearch

  private removeConditionsData(label: string) {
    this.timelineSearch.removeConditionsData(label)
    this.conditionsDatas = this.conditionsDatas.filter((condition: { label: string }) => condition.label !== label)
    this.setTimelineCookie()
  }

  private async setTimelineCookie() {
    // クッキーに検索条件をセット
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['timelineConditions'] = this.conditionsDatas
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  @Watch('conditionsDatas', { deep: true })
  private conditionsDatasChange() {
    // 条件が設定された時の初回取得
    this.timelineDatas = []
    this.lastData = {}
    this.loadDatas()
    this.observer.observe(this.observeElement)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  private observer!: IntersectionObserver
  @Ref() observeElement!: HTMLElement

  private mounted() {
    this.observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]
        if (entry && entry.isIntersecting) {
          if (Object.keys(this.lastData).length) {
            // 追加取得
            this.loadDatas()
          }
        }
      },
      { rootMargin: '200px 0px' }
    )
    this.observer.observe(this.observeElement)
  }
}
